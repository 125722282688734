import React from "react";
import Layout from "../components/layout";
import SEO from "../components/Seo";
import { Link } from "gatsby";

import christos from "../assets/images/artists/christos/profile/christos-artist-profile-medium.jpg";
import christos001 from "../assets/images/artists/christos/work/christos001.jpg";
import christos002 from "../assets/images/artists/christos/work/christos002.jpg";
import christos003 from "../assets/images/artists/christos/work/christos003.jpg";
import christos004 from "../assets/images/artists/christos/work/christos004.jpg";
import christos005 from "../assets/images/artists/christos/work/christos005.jpg";
import christos006 from "../assets/images/artists/christos/work/christos006.jpg";
import christos007 from "../assets/images/artists/christos/work/christos007.jpg";
import christos008 from "../assets/images/artists/christos/work/christos008.jpg";
import christos009 from "../assets/images/artists/christos/work/christos009.jpg";
import christos010 from "../assets/images/artists/christos/work/christos010.jpg";
import christos011 from "../assets/images/artists/christos/work/christos011.jpg";
import christos012 from "../assets/images/artists/christos/work/christos012.jpg";
import christos013 from "../assets/images/artists/christos/work/christos013.jpg";
import christos014 from "../assets/images/artists/christos/work/christos014.jpg";
import christos015 from "../assets/images/artists/christos/work/christos015.jpg";
import christos016 from "../assets/images/artists/christos/work/christos016.jpg";
import christos017 from "../assets/images/artists/christos/work/christos017.jpg";
import christos018 from "../assets/images/artists/christos/work/christos018.jpg";
import dmon from "../assets/images/artists/dmon/profile/dmon-artist-profile-medium.jpg";
import emm from "../assets/images/artists/emm/profile/emm-artist-profile.jpg";

import sofia from "../assets/images/artists/sofia/profile/sofia-artist-profile.jpg";
import jeanine from "../assets/images/artists/jeanine/profile/jeanine-artist-profile.jpeg";
import rick from "../assets/images/artists/rick/profile/rick-artist-profile.jpg";
import tony from "../assets/images/artists/tony/profile/tony-artist-profile.jpg";

const Artist_Christos = props => (
  <Layout>
    <SEO
      title="Artist christos The Architect"
      keywords={[
        `tattoos in los angeles`,
        `christos the architect`,
        `los angeles tattoo artists`,
        `obsidian la tattoo`,
        `tattoos los angeles`,
        `la tattoo studios`
      ]}
    />

    <div id="main" className="alt">
      <div className="inner">
        <header className="major">
          <h1>Christos</h1>
        </header>
        <div className="image">
          <img
            src={christos}
            alt="christos the architect"
            className="image left"
          />
          <p>
            Christos Tejada (b.1989) Queer Tattooist/Multimedia Artist currently
            living and working in Los Angeles, CA. Using the construction of
            traditional sigil methods and stylized pictorial elements to
            construct a tattoo as declaration of intentions. Tattoos easily
            become a catalyst for magikal desires and sometimes even will waken
            these desires, causing changes to occur in ones true will.
          </p>
          <ul
            className="actions"
            style={{
              display: `flex`,
              flexDirection: `row`,
              justifyContent: `space-between`
            }}
          >
            <li>
              <a href="#contact" className="button next left scrolly fit">
                Booking
              </a>
            </li>
          </ul>
          <hr />
          <h4 style={{ float: `left` }}></h4>
          <ul
            className="actions"
            style={{
              display: `flex`,
              flexDirection: `row`,
              justifyContent: `space-between`
            }}
          >
            <li>
              {" "}
              <a
                href="https://www.instagram.com/christttoss/?hl=en"
                className="icon alt fa-instagram"
              >
                <span className="label">Instagram</span>
                &nbsp; @christttoss
              </a>
            </li>
            <li>Email: info@christostejada.com</li>
            <li>
              Website:{" "}
              <a href="https://www.christostejada.com/">christostejada.com</a>
            </li>
          </ul>
        </div>

        <section
          className="tiles"
          style={{ width: `100%`, justifyContent: `center`, margin: `-1em` }}
        >
          <article
            style={{
              backgroundImage: `url(${christos001})`,
              backgroundSize: `cover`,
              margin: `1em`,
              width: `25%`
            }}
          ></article>
          <article
            style={{
              backgroundImage: `url(${christos002})`,
              backgroundSize: `cover`,
              margin: `1em`,
              width: `25%`
            }}
          ></article>
          <article
            style={{
              backgroundImage: `url(${christos003})`,
              backgroundSize: `cover`,
              margin: `1em`,
              width: `25%`
            }}
          ></article>
          <article
            style={{
              backgroundImage: `url(${christos004})`,
              backgroundSize: `cover`,
              margin: `1em`,
              width: `25%`
            }}
          ></article>
          <article
            style={{
              backgroundImage: `url(${christos005})`,
              backgroundSize: `cover`,
              margin: `1em`,
              width: `25%`
            }}
          ></article>
          <article
            style={{
              backgroundImage: `url(${christos006})`,
              backgroundSize: `cover`,
              margin: `1em`,
              width: `25%`
            }}
          ></article>
          <article
            style={{
              backgroundImage: `url(${christos007})`,
              backgroundSize: `cover`,
              margin: `1em`,
              width: `25%`
            }}
          ></article>
          <article
            style={{
              backgroundImage: `url(${christos008})`,
              backgroundSize: `cover`,
              margin: `1em`,
              width: `25%`
            }}
          ></article>
          <article
            style={{
              backgroundImage: `url(${christos009})`,
              backgroundSize: `cover`,
              margin: `1em`,
              width: `25%`
            }}
          ></article>
          <article
            style={{
              backgroundImage: `url(${christos010})`,
              backgroundSize: `cover`,
              margin: `1em`,
              width: `25%`
            }}
          ></article>
          <article
            style={{
              backgroundImage: `url(${christos011})`,
              backgroundSize: `cover`,
              margin: `1em`,
              width: `25%`
            }}
          ></article>
          <article
            style={{
              backgroundImage: `url(${christos012})`,
              backgroundSize: `cover`,
              margin: `1em`,
              width: `25%`
            }}
          ></article>
          <article
            style={{
              backgroundImage: `url(${christos013})`,
              backgroundSize: `cover`,
              margin: `1em`,
              width: `25%`
            }}
          ></article>
          <article
            style={{
              backgroundImage: `url(${christos014})`,
              backgroundSize: `cover`,
              margin: `1em`,
              width: `25%`
            }}
          ></article>
          <article
            style={{
              backgroundImage: `url(${christos015})`,
              backgroundSize: `cover`,
              margin: `1em`,
              width: `25%`
            }}
          ></article>
          <article
            style={{
              backgroundImage: `url(${christos016})`,
              backgroundSize: `cover`,
              margin: `1em`,
              width: `25%`
            }}
          ></article>
          <article
            style={{
              backgroundImage: `url(${christos017})`,
              backgroundSize: `cover`,
              margin: `1em`,
              width: `25%`
            }}
          ></article>
          <article
            style={{
              backgroundImage: `url(${christos018})`,
              backgroundSize: `cover`,
              margin: `1em`,
              width: `25%`
            }}
          ></article>
        </section>
        <header className="major">
          <h1>More Artists</h1>
        </header>
        <section id="artists" className="tiles">
          <article style={{ backgroundImage: `url(${dmon})` }}>
            <header className="major">
              <h3>D'mon - The Architect</h3>
              <p>See work</p>
            </header>
            <Link to="/artist-dmon" className="link primary"></Link>
          </article>
          <article style={{ backgroundImage: `url(${emm})` }}>
            <header className="major">
              <h3>Emily Kay</h3>
              <p>See work</p>
            </header>
            <Link to="/artist-emm" className="link primary"></Link>
          </article>

          <article style={{ backgroundImage: `url(${jeanine})` }}>
            <header className="major">
              <h3>Jeanine</h3>
              <p>See work</p>
            </header>
            <Link to="/artist-jeanine" className="link primary"></Link>
          </article>
          <article style={{ backgroundImage: `url(${tony})` }}>
            <header className="major">
              <h3>Tony</h3>
              <p>See work</p>
            </header>
            <Link to="/artist-tony" className="link primary"></Link>
          </article>
        </section>
      </div>
    </div>
  </Layout>
);

// export const query = graphql`
// query Artistchristos{
//     allInstaNode (limit: 25) {
//         edges {
//             node {
//             id
//             likes
//             original
//             localFile{
//                 childImageSharp {
//                     fluid(maxHeight: 293, maxWidth: 293, quality: 50) {
//                         ...GatsbyImageSharpFluid_withWebp_tracedSVG
//                         }
//                     }
//                 }
//             }
//         }
//     }
// }
// `
export default Artist_Christos;
